<template>
  <div class="app-card">
    <a-form-model
      ref="createAndEditForm"
      :model="createAndEditForm"
      :rules="createAndEditFormRules"
      v-bind="formLayout"
    >
      <a-form-model-item label="系统简介" prop="introduction">
        <quill-editor
          style="height: 140px"
          v-model="createAndEditForm.introduction"
          :options="{ placeholder: '请填写内容', ...editerOptions }"
        />
      </a-form-model-item>
      <a-form-model-item
        style="padding-top: 90px"
        label="系统特色"
        prop="features"
      >
        <quill-editor
          style="height: 140px"
          v-model="createAndEditForm.features"
          :options="{ placeholder: '请填写内容', ...editerOptions }"
        />
      </a-form-model-item>
    </a-form-model>
    <div style="padding-top:140px">
      <a-button
      type="primary"
      @click="saveAndUpdate"
      v-if="
        $root.$data.privateState.action.includes(
          '/Document/PrivacyPolicy'
        ) || $root.$data.privateState.role === 'SuperAdmin'
      "
      >保存并更新</a-button
    >
    </div>
  </div>
</template>

<script>
import { Document } from "@/api";

export default {
  name: "SystemIntroductionAndFeatures",
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      vm.getDetail();
    });
  },
  data() {
    return {
      createAndEditForm: {
        introduction: "",
        features: "",
      },
      createAndEditFormRules: {
        introduction: [{ required: true, message: "请填写", trigger: "blur" }],
        features: [{ required: true, message: "请填写", trigger: "blur" }],
      },
    };
  },
  methods: {
    async getDetail() {
      const introductionRes = await this.$axios
        .get(`${Document}/GetDetail?key=Introduction`)
        .catch((e) => {
          return {
            status: false,
            message: e.message.indexOf("401") > -1 ? "登录超时" : e.message,
          };
        });
      if (introductionRes.status) {
        this.createAndEditForm.introduction = introductionRes.data;
      }
      const featuresRes = await this.$axios
        .get(`${Document}/GetDetail?key=Features`)
        .catch((e) => {
          return {
            status: false,
            message: e.message.indexOf("401") > -1 ? "登录超时" : e.message,
          };
        });
      if (featuresRes.status) {
        this.createAndEditForm.features = featuresRes.data;
      }
    },
    saveAndUpdate() {
      this.$refs.createAndEditForm.validate(async (valid) => {
        if (!valid) {
          this.$message.error("请检查填写内容");
          return;
        }
        const apiRes = await this.$axios
          .post(`${Document}/IntroductionAndFeatures`, this.createAndEditForm)
          .catch((e) => {
            return {
              status: false,
              message: e.message.indexOf("401") > -1 ? "登录超时" : e.message,
            };
          });
        if (!apiRes.status) {
          this.$message.error(apiRes.message);
          return;
        }
        this.$message.success("系统简介和特色已更新");
      });
    },
  },
};
</script>

<style>
</style>